import { useGetContractIframeUrl } from '@src/api/contract-api'
import { useGetCreditApplicationById } from '@src/api/credit-api'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { EContractStatus, EWorksheetStatus } from '@src/types'
import { Loader } from '@src/components'
import ErrorPage from '../ErrorPage'
import { contractIsReceived } from '../ViewCreditApplication/HardHitApprovedResultPage/hooks'

type Props = {
  creditAppId: string
}

const PROGRESSION_INTERVAL_STEP = 100 / 180 // 180 sec (3 minutes) / 100%

const ContractWaitingLoader = ({ creditAppId }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [shouldPollCreditApp, setShouldPollCreditApp] = useState(true)
  const [shouldPollContract, setShouldPollContract] = useState(false)
  const [creditApp] = useGetCreditApplicationById(creditAppId, shouldPollCreditApp, 14000)
  const { data: iframeDto, failureCount } = useGetContractIframeUrl(creditAppId, shouldPollContract)
  const [progress, setProgress] = useState<number>(0)

  if (creditApp && (creditApp.worksheet?.status === EWorksheetStatus.Draft || contractIsReceived(creditApp))) {
    navigate(`/creditapplication/${creditAppId}`)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((p) => {
        if (p >= 100 - PROGRESSION_INTERVAL_STEP) clearInterval(interval)
        const np = p + PROGRESSION_INTERVAL_STEP
        if (np > 100) return 100
        return np
      })
    }, 1000) // 1 sec

    const timeoutPollCreditApp = setTimeout(() => {
      setShouldPollCreditApp(false)
      clearInterval(interval)
    }, 60000) // 1 minute

    return () => {
      clearTimeout(timeoutPollCreditApp)
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (creditApp?.contract.status === EContractStatus.Ready) {
      setShouldPollCreditApp(false)
      setProgress((1 / 3) * 100) // 1/3
      setShouldPollContract(true)
    }
  }, [creditApp?.contract.status])

  const urlsDto = useMemo(() => iframeDto?.urls?.find((x) => x.isForApplicant === true), [iframeDto?.urls])

  useEffect(() => {
    if (urlsDto?.url) window.location.href = urlsDto.url
  }, [navigate, urlsDto?.url])

  if (failureCount === 6 || (!shouldPollCreditApp && creditApp?.contract.status !== EContractStatus.Ready))
    return <ErrorPage title={t('contract.errorMessage')} backhref={`/creditapplication/${creditAppId}`} />

  return <Loader title={t('contract.waiting')} description={t('contract.waitingDescription')} progression={progress} />
}

export default ContractWaitingLoader
