import { CSSProperties } from 'react'

type Button = {
  id: string
  label: string
  onClick: () => void
}

type Props = {
  type: 'error' | 'warn' | 'success'
  message: string
  style?: CSSProperties
  buttons?: Button[]
}
const Alert: React.FC<Props> = ({ type, message, buttons, style }) => {
  return (
    <div className={`alert ${type}`} style={{ display: 'flex', alignItems: 'center', ...style }}>
      <i
        className={`fa-solid fa-${type === 'success' ? 'circle-check' : 'hexagon-exclamation'}`}
        style={{ marginRight: 5 }}
      />
      <span style={{ flex: 1 }}>{message}</span>
      {buttons?.map((button) => (
        <button className="btn btn-grey btn-alert" key={button.id} style={{ marginLeft: 5 }} onClick={button.onClick}>
          {button.label}
        </button>
      ))}
    </div>
  )
}

export default Alert
