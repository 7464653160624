import { Trans, useTranslation } from 'react-i18next'
import { FormatCurrency, FormatCurrencyNoDecimals, FormatCurrencyRoundedUpToNoDecimals } from '@src/services/Formatter'

import { EditWorksheetDto, useUpdateWorksheet } from '@src/api/credit-api'
import { AsyncActionButton, Loader } from '@src/components'
import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'
import { reportErrorToConsole } from '@src/services/error-logger'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { getLoanAmount } from '@src/data/creditapp-selectors'
import CancelAppButton from '@src/components/CancelAppButton'
import { Constants, EPaymentFrequency, EWorksheetStatus, PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import { Link } from 'react-router-dom'
import useTermPaymentsTable from './hooks'

type Props = {
  creditApp: FilteredCreditApplication
}
const InitialApprobationForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const [monthlyPaymentPerMonths, isLoadingTermTable] = useTermPaymentsTable(creditApp)
  const [submitWorksheetUpdate, isSubmitting] = useUpdateWorksheet()

  useTrackStepReachedEvent(creditApp, ETrackedEvents.QualifSuccess)

  const submitTerm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (creditApp) {
      const term = monthlyPaymentPerMonths[monthlyPaymentPerMonths.length - 1].term

      const dto: EditWorksheetDto = {
        term,
        amountRequested: getLoanAmount(creditApp),
        creditApplicationId: creditApp.id,
        paymentFrequency: EPaymentFrequency.Monthly,
        deliveryOn: null,
        firstPaymentOn: null,
        includeInsurance: false,
        status: EWorksheetStatus.Draft,
        create: creditApp.worksheet === null,
        versionTag: creditApp.worksheet?.versionTag ?? null,
      }
      submitWorksheetUpdate(dto).catch(reportErrorToConsole)
    }
  }

  if (isLoadingTermTable || !creditApp)
    return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />

  const requestedAmountReached = creditApp.requestedLoanAmount <= creditApp.finalDecision.maxAmountFinanced
  const loanAmount = Math.min(creditApp.requestedLoanAmount, creditApp.finalDecision.maxAmountFinanced)

  return (
    <main className="general-message" style={{ display: 'block' }}>
      <form onSubmit={submitTerm}>
        <div id="loan-form-container">
          <h3>
            {requestedAmountReached && (
              <>
                <i className="fa-solid fa-party-horn" /> {t('common.congratulation')}{' '}
              </>
            )}
            {t('decision.hardhitDecisionApprovedTitle')}
          </h3>
          <div className="paragraph">
            {monthlyPaymentPerMonths && monthlyPaymentPerMonths.length > 0 && (
              <>
                <p>
                  <Trans
                    i18nKey="decision.hardhitDecisionApproved"
                    values={{
                      maximumAmount: FormatCurrencyNoDecimals(creditApp.finalDecision.maxAmountFinanced),
                      minPaymentAmount: FormatCurrency(
                        monthlyPaymentPerMonths[monthlyPaymentPerMonths.length - 1].amountWithoutInsurance,
                      ),
                    }}
                  />
                </p>
                <p style={{ textAlign: 'center', marginTop: '16px' }}>
                  <Trans
                    i18nKey="loan.youHaveQuestions"
                    values={{
                      phoneNumber: PHONE_NUMBER,
                    }}
                    components={{ a: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} /> }}
                  />
                </p>
              </>
            )}
          </div>
          <div className="btn-group mobile-reversed space-between full-width-mobile">
            <CancelAppButton creditAppId={creditApp.id} />
            <AsyncActionButton type="submit" className="btn btn-blue" isPending={isSubmitting} primary>
              <span>
                {requestedAmountReached
                  ? t('common.continue')
                  : t('common.continueWith', { amount: FormatCurrencyRoundedUpToNoDecimals(loanAmount) })}
              </span>
              <i className="fa-regular fa-arrow-right" />
            </AsyncActionButton>
          </div>
          <div style={{ fontSize: '1.5rem', marginTop: '4rem' }}>
            <sup style={{ color: 'red' }}>*</sup>
            &nbsp;
            {t('common.monthlyAmountsInclude', {
              financeFee: Math.round(Constants.FinanceFeeRateForRegular * 100),
              interestRate: creditApp.finalDecision.interestRate,
            })}
          </div>
        </div>
      </form>
    </main>
  )
}

export default InitialApprobationForm
