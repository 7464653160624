import SectionTitle from '@src/components/PageSectionTitle'
import StepCard from '@src/components/Card/Card'
import StepCardContainer from '@src/components/Card/CardContainer'
import useFaqLink from '@src/hooks/useFaqLink'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

const WelcomePage = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const faqLink = useFaqLink()

  return (
    <main className="step-0">
      <SectionTitle title={t('welcome.applyInMinutes')} />
      <h1>{t('welcome.prequalifiedRapidly')}</h1>
      <div className="paragraph">
        <p>{t('welcome.answerQuestions')}</p>
      </div>
      <StepCardContainer>
        <StepCard icon="address-card" description={t('welcome.yourself')} />
        <StepCard icon="memo-circle-check" description={t('welcome.validateIcome')} />
        <StepCard icon="hand-holding-circle-dollar" description={t('welcome.selectPlan')} />
      </StepCardContainer>
      <button
        id="btnBegin"
        type="button"
        onClick={() => navigate(`/eligibility`, { replace: true })}
        className="btn btn-blue"
      >
        {t('welcome.getStarted')}
        <i className="fa-regular fa-arrow-right" />
      </button>
      <p style={{ fontSize: '1.5rem', marginTop: '1rem' }}>
        <Trans
          i18nKey="welcome.question"
          components={{
            site_anchor: <Link to={faqLink} target="_blank" />,
          }}
        />
      </p>
    </main>
  )
}

export default WelcomePage
