import { useGetCreditApplicationById } from '@src/api/credit-api'
import { Loader } from '@src/components'
import BackButton from '@src/components/BackButton'
import { getStringFromQueryString } from '@src/services/query-string'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { contractIsReceived } from './ViewCreditApplication/HardHitApprovedResultPage/hooks'

const ConsignoCallBack = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const [shouldPollCreditApp, setShouldPollCreditApp] = useState(true)
  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(id!, shouldPollCreditApp)

  const status = getStringFromQueryString(searchParams, 'status')

  const contractReceived = contractIsReceived(creditApp)

  useEffect(() => {
    if (!isFetchingCreditApp && !contractReceived) {
      setShouldPollCreditApp(false)
    }
  }, [isFetchingCreditApp, setShouldPollCreditApp, contractReceived])

  useEffect(() => {
    if (!creditApp && !isFetchingCreditApp) navigate('', { replace: true })
    else if (creditApp && !contractReceived && !status && !isFetchingCreditApp)
      navigate(`/creditapplication/${id}`, { replace: true })
  }, [contractReceived, creditApp, id, isFetchingCreditApp, navigate, status])

  if ((status && creditApp) || contractReceived)
    return (
      <main id="flinks-validation" className="general-message">
        <div style={{ alignSelf: 'center', textAlign: 'center' }}>
          <h1 style={{ marginBottom: '1rem' }}>{t('common.thankYou')}</h1>
          <h3>{t('contract.received')}</h3>
          <h3>{t('contract.review')}</h3>

          <BackButton href={`/creditapplication/${id}`} replace />
        </div>
      </main>
    )

  return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
}

export default ConsignoCallBack
