import { PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'

const ConsentFr = () => {
  return (
    <div className="terms-conditions">
      <h1 className="terms-conditions-subtitle">Consentement</h1>
      <ol className="terms-conditions-list">
        <li>
          J&apos;autorise iFinance Canada Inc., ou tout fournisseur de services ayant été mandaté par iFinance Canada
          Inc., à recueillir, vérifier et/ou obtenir tout renseignement personnel me concernant, incluant notamment,
          mais sans s&apos;y limiter, mes relevés bancaires ainsi qu&apos;un spécimen chèque, dans la mesure où ceux-ci
          sont nécessaires à l&apos;analyse, à l&apos;évaluation ou à la gestion de mon dossier ou permettent, le cas
          échéant, à iFinance Canada Inc. d&apos;exercer ses recours et autres moyens prévus au contrat me liant à
          credit@ifinancecanada.com, le cas échéant, et ce, auprès de toute personne ou de tout organisme, dont
          notamment mais non limitativement auprès de mon employeur, du propriétaire des lieux où je réside advenant que
          je sois locataire ainsi qu&apos;auprès de toute agence d&apos;évaluation de crédit, assureur, prêteur
          hypothécaire, banque à charte, caisse populaire, prêteur et/ou toute personne avec qui j&apos;entretiens ou
          j&apos;ai entretenu des relations financières, de les utiliser, de les transmettre, de les conserver et/ou de
          les communiquer afin de, notamment mais non limitativement confirmer mon identité et mon emploi, me contacter,
          évaluer ma situation financière et professionnelle, déterminer si ma demande de crédit est recevable et si je
          respecte les normes de souscription d&apos;iFinance Canada Inc., gérer ma demande de crédit et/ou le prêt qui
          pourrait éventuellement m&apos;être accordé, prendre, lorsque nécessaire des mesures de recouvrement, se
          conformer aux lois ou aux règlements et prévenir, détecter et gérer des possibles fraudes ou autres activités
          criminelles. J&apos;autorise également par la présente toute personne avec qui iFinance Canada Inc. pourrait
          communiquer à cet égard à fournir, transmettre et communiquer à cette dernière les renseignements demandés
          conformément au présent consentement.
        </li>

        <li>
          Je confirme que le présent consentement prendra effet à compter de ce jour et demeurera valide pour toute la
          durée du contrat me liant à iFinance Canada Inc., le cas échéant, incluant pour l&apos;exercice des recours ou
          autres moyens prévus au ou découlant de ce contrat. Pour fins de clarification, iFinance Canada Inc. pourra
          utiliser le présent consentement pour obtenir mes relevés bancaires et ce, tout au long du contrat me liant à
          cette dernière et même après, si cela s&apos;avère nécessaire pour l&apos;exercice des droits et recours
          d&apos;iFinance Canada Inc. prévus au ou découlant du contrat.
        </li>
        <li>Je confirme avoir la capacité légale de donner ce consentement.</li>
      </ol>
      <h3 className="terms-conditions-subtitle">
        Communiquer avec le responsable de la protection des renseignements personnels et du respect de la vie privée
        d&apos;iFinance Canada Inc.{' '}
      </h3>
      <p>
        Pour toute question relative au présent consentement ou sur les pratiques d&apos;iFinance Canada Inc. en matière
        de protection des renseignements personnels et du respect de la vie privée, veuillez-vous adresser au
        responsable du respect de la vie privée d&apos;iFinance Canada Inc. aux coordonnées suivantes :
      </p>
      <ul className="terms-conditions-list">
        <li>
          Par courriel: <a href="mailto:credit@ifinancecanada.com">credit@ifinancecanada.com</a>
        </li>
        <li>Par la poste: 55 Bloor Street, Box 19645, Toronto, ON, M4W 3T9</li>
        <li>
          Par téléphone: <a href={PHONE_NUMBER_LINK}>{PHONE_NUMBER}</a>
        </li>
      </ul>

      <h3 className="terms-conditions-subtitle">Retirer votre consentement</h3>

      <p>
        Vous pouvez retirer en tout temps le consentement que vous avez donné en vertu des présentes. Pour ce faire,
        vous devez communiquer par écrit avec le responsable, par la poste ou par courriel, aux coordonnées mentionnées
        ci-haut. Veuillez noter que celui-ci peut vous demander de vous identifier.{' '}
        <b>
          <u>
            Toutefois, prenez avis que le retrait de votre consentement à la collecte, à l&apos;utilisation ou à la
            divulgation de certains renseignements personnels peut faire en sorte qu&apos;iFinance Canada Inc. ne soit
            plus en mesure de vous offrir certains services, dont notamment de vous octroyer le prêt demandé ou
            acquiescer à celui-ci ou que le prêt qui vous a été octroyé devienne dû et exigible.
          </u>
        </b>
      </p>
      <p>
        Vous pouvez demander à tout moment d&apos;avoir accès aux renseignements personnels recueillis sur vous, ainsi
        qu&apos;à ce que ceux-ci soient modifiés ou supprimés. Pour présenter une telle demande, veuillez communiquer
        par écrit avec le responsable, par la poste ou par courriel, aux coordonnées mentionnées ci-dessus.
      </p>

      <h3 className="terms-conditions-subtitle">Conservation de vos renseignements personnels</h3>

      <p>
        Une fois que les transactions visées par le présent consentement auront été complétées et aux termes du contrat
        vous liant à iFinance Canada Inc., vos renseignements personnels seront uniquement conservés dans la mesure
        prévue et/ou exigée par la loi.
      </p>
      <br />
    </div>
  )
}

export default ConsentFr
