import { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Constants } from '@src/types'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { ETrackedEvents, useTrackStepReachedEvent } from '@src/services/trackingEvents'

import ExpandableSection from '@src/components/ExpandableSection'
import SinInput from '@src/components/SinInput'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useSubmitHardHit } from '@src/api/credit-api'
import AcceptTermsCheckbox from '@src/components/AcceptTermsCheckbox'
import AsyncActionButton from '@src/components/AsyncActionButton'
import {
  getPrequalifiedAmount,
  getMinPaymentForRequestedAmount,
  getMaxPaymentForRequestedAmount,
  mustAskApplicantSIN,
} from '@src/data/creditapp-selectors'
import {
  FormatCurrencyRoundedUpToNoDecimals,
  FormatCurrencyToNearestLowerMultipleToNoDecimals,
  FormatCurrencyToNearestUpperMultipleToNoDecimals,
} from '@src/services/Formatter'
import CancelAppButton from '@src/components/CancelAppButton'
import { EditSinAndLoanAmount, buildEditSinAndLoanAmountSchema } from '../../components/EditPrequalificationSchema'
import { useComputeMinMaxPaymentForLoanAmount } from '../../credit-hooks'
import MonthlyPaymentRange from './MonthlyPaymentRange'

type Props = {
  creditApp: FilteredCreditApplication
}

const ApprovedPrequalForm = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const initialPrequalifiedAmount = getPrequalifiedAmount(creditApp)
  const minPaymentForInitialApprovedAmount = getMinPaymentForRequestedAmount(creditApp)
  const maxPaymentForInitialApprovedAmount = getMaxPaymentForRequestedAmount(creditApp)
  const askApplicantSIN = mustAskApplicantSIN(creditApp)

  useTrackStepReachedEvent(creditApp, ETrackedEvents.PrequalSuccess)

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<EditSinAndLoanAmount>({
    mode: 'onBlur',
    defaultValues: {
      needsApplicantSin: askApplicantSIN,
      requestedLoanAmount: initialPrequalifiedAmount,
      id: creditApp.id,
    },
    resolver: yupResolver(buildEditSinAndLoanAmountSchema(creditApp.prequalificationDecision.maxLoanAmount)),
  })

  const updatedMonthlyPayment = useComputeMinMaxPaymentForLoanAmount(creditApp, creditApp.requestedLoanAmount)

  const [submitHardHit, isSubmitting] = useSubmitHardHit()
  const innerSubmit = useCallback(
    async (data: EditSinAndLoanAmount) => {
      await submitHardHit(data)
    },
    [submitHardHit],
  )

  const requestedAmountReached = creditApp.requestedLoanAmount <= creditApp.finalDecision.maxAmountFinanced

  const mustShowUpdatedMonthlyPayment =
    Math.abs(maxPaymentForInitialApprovedAmount - updatedMonthlyPayment[1]) > 5 ||
    Math.abs(minPaymentForInitialApprovedAmount - updatedMonthlyPayment[0]) > 5

  return (
    <main className="general-message" style={{ display: 'block' }}>
      <form onSubmit={handleSubmit(innerSubmit)}>
        <h1 className="h3" style={{ lineHeight: 1.5 }}>
          <Trans
            i18nKey="prequalification.prequalified"
            values={{
              requestedAmount: FormatCurrencyRoundedUpToNoDecimals(initialPrequalifiedAmount),
              minMonthlyPayment: FormatCurrencyToNearestLowerMultipleToNoDecimals(
                minPaymentForInitialApprovedAmount,
                5,
              ),
              maxMonthlyPayment: FormatCurrencyToNearestUpperMultipleToNoDecimals(
                maxPaymentForInitialApprovedAmount,
                5,
              ),
            }}
          />
        </h1>
        <div className="paragraph">
          <div className="information">
            <Trans i18nKey="prequalification.loanDetails.mainDetails" />
          </div>
          <ExpandableSection toggleText="prequalification.loanDetails.learnMore">
            <p
              style={{
                fontStyle: 'italic',
                border: '1px solid #08abf0',
                borderRadius: '1.5rem',
                textAlign: 'center',
                padding: '1.5rem',
              }}
            >
              {t('prequalification.loanDetails.learnMoreDetails')}
            </p>
          </ExpandableSection>

          {mustShowUpdatedMonthlyPayment && (
            <MonthlyPaymentRange min={updatedMonthlyPayment[0]} max={updatedMonthlyPayment[1]} />
          )}

          {askApplicantSIN && (
            <SinInput
              error={errors.applicantSin}
              id="applicantSin"
              reason="prequalification.applicantCreditLessThan2Years"
              {...register('applicantSin')}
            />
          )}

          <div style={{ marginTop: '32px' }}>{t('prequalification.pullCreditReport')}</div>
          <AcceptTermsCheckbox
            id="acceptConditions"
            termId={t('prequalification.acceptConditions')}
            msgOnInvalid={t('common.acceptConditions')}
          />
        </div>

        <div className="btn-group mobile-reversed justify-end">
          <CancelAppButton creditAppId={creditApp.id} />
          <AsyncActionButton type="submit" isPending={isSubmitting} primary style={{ justifyContent: 'center' }}>
            <span>
              {requestedAmountReached
                ? t('prequalification.continue')
                : t('common.continueWith', {
                    amount: FormatCurrencyRoundedUpToNoDecimals(initialPrequalifiedAmount),
                  })}
            </span>
            <i className="fa-regular fa-arrow-right" />
          </AsyncActionButton>
        </div>

        <div style={{ borderTop: '1px solid black', marginTop: '5rem' }}>
          <p style={{ marginTop: '2rem' }}>
            <Trans
              i18nKey="prequalification.priorToQualifyNotes.1"
              values={{
                minInterestRate: creditApp.prequalificationDecision.minInterestRate,
                ageConditionalTerm:
                  creditApp.applicant.age >= Constants.UpperThresholdAgeForApplicant
                    ? Constants.CorrectedMaxTerm
                    : Constants.BaseMaxTerm,
              }}
            />
          </p>
          <p>{t('prequalification.priorToQualifyNotes.estimate')}</p>
          <p style={{ marginTop: '2rem' }}>{t('prequalification.priorToQualifyNotes.2')}</p>
        </div>
      </form>
    </main>
  )
}

export default ApprovedPrequalForm
