import { ReactElement } from 'react'
import type Card from './Card'
import type { CardProps } from './Card'

type Props = {
  children: ReactElement<CardProps, typeof Card> | ReactElement<CardProps, typeof Card>[]
}

const CardContainer = ({ children }: Props) => {
  return <div className="card-container">{children}</div>
}

export default CardContainer
