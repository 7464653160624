import BackButton from '@src/components/BackButton'
import SectionTitle from '@src/components/PageSectionTitle'
import { useTranslation } from 'react-i18next'

type Props = {
  title?: string
  description?: string
  backhref?: string
}

const ErrorPage = ({ title, description, backhref }: Props) => {
  const { t } = useTranslation()
  return (
    <main className="step-0">
      <SectionTitle title={title ?? t('common.notFound')} />

      <h1>{t('common.oopsTitle')}</h1>
      <div className="paragraph">{description ?? t('common.unexpectedError')}</div>

      <BackButton href={backhref ?? '/'} />
    </main>
  )
}

export default ErrorPage
