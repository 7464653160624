import { FC } from 'react'

export type CardProps = {
  icon: string
  title?: string
  description: string
}

const Card: FC<CardProps> = ({ icon, title, description }) => {
  return (
    <div className="applicationInfo-card card">
      <div>
        {title && <h3>{title}</h3>}
        <p className="paragraph">{description}</p>
      </div>
      <i className={`fa-regular fa-${icon}`} />
    </div>
  )
}

export default Card
