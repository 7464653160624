import { ErrorPage, Loader } from '@src/components'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useGetIdentityVerificationSession } from '@src/api/identity-verification-session-api'
import VouchedComponent from './VouchedComponent'

type Props = {
  creditApp: FilteredCreditApplication
}

const VouchedPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [session, isLoading] = useGetIdentityVerificationSession(id!)

  if (isLoading) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  if (creditApp.vouchedFailedCount > 1)
    return <ErrorPage title={t('common.unexpectedError')} description={t('identification.failed_no_retry')} />

  return <VouchedComponent id="vouched-element" session={session} creditApplication={creditApp} />
}

export default VouchedPage
