import { Address } from '@src/types/CreditApplicationSchema'
import i18next from 'i18next'

export function FormatCurrency(data?: number | string | null | undefined, decimals = 2) {
  const { language } = i18next
  let amount = 0
  if (data) {
    amount = parseFloat(data.toString())
  }
  return amount
    .toLocaleString(`${language}-CA`, {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    .replace(' ', '\u202F')
}

export const convertToLocalDate = (date: string) => {
  return new Date(date.replace(/-/g, '/'))
}

export function FormatCurrencyNoDecimals(data?: number | null) {
  return FormatCurrency(data, 0)
}

export const formatPhone = (phoneNumber: string | undefined | null): string => {
  if (!phoneNumber) return 'N/A'
  return `+1 (${phoneNumber?.slice(0, 3)}) ${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}`
}

export const phoneFormat = (phone: string) => {
  if (!phone) return phone
  let formatedInputVal = phone.replace(/\D/g, '')
  const size = formatedInputVal.length
  if (size > 0) {
    formatedInputVal = `(${formatedInputVal}`
  }
  if (size > 3) {
    formatedInputVal = `${formatedInputVal.slice(0, 4)}) ${formatedInputVal.slice(4)}`
  }
  if (size > 6) {
    formatedInputVal = `${formatedInputVal.slice(0, 9)}-${formatedInputVal.slice(9)}`
  }
  if (size > 10) {
    formatedInputVal = `${formatedInputVal.slice(0, 14)}`
  }
  return formatedInputVal
}

export const formatPhonesNumber = () => {
  const phones = document.querySelectorAll('.phone-input')

  function handleInput(this: HTMLInputElement) {
    this.value = phoneFormat(this.value)
  }

  phones.forEach((elem) => {
    elem.addEventListener('input', handleInput)
  })
}

export const removePhoneFormat = (phoneFormated: string) => {
  let phone = phoneFormated.replace(' ', '')
  phone = phone.replace('(', '')
  phone = phone.replace(')', '')
  phone = phone.replace('-', '')

  return phone
}

export const formatAddress = (address: Address) => {
  if (!address) return 'N/A'
  let ret = ''
  if (address.civicNumber) ret = ret.concat(address.civicNumber)
  if (address.street) ret = ret.concat(` ${address.street}`)
  if (address.city) ret = ret.concat(`, ${address.city}`)
  if (address.stateIso) ret = ret.concat(`, ${address.stateIso}`)
  if (address.country) ret = ret.concat(`, ${address.country}`)
  if (address.postalCode) ret = ret.concat(`, ${address.postalCode?.toUpperCase()}`)
  return ret
}

export const sinFormat = (sin: string) => {
  if (!sin) return sin
  let formatedInputVal = sin.replace(/\D/g, '')
  const size = formatedInputVal.length
  if (size > 3) {
    formatedInputVal = `${formatedInputVal.slice(0, 3)}-${formatedInputVal.slice(3)}`
  }
  if (size > 6) {
    formatedInputVal = `${formatedInputVal.slice(0, 7)}-${formatedInputVal.slice(7)}`
  }
  if (size > 9) {
    formatedInputVal = `${formatedInputVal.slice(0, 11)}`
  }
  return formatedInputVal
}

export const formatSIN = () => {
  const sin = document.querySelectorAll('.sin-input')

  function handleInput(this: HTMLInputElement) {
    this.value = sinFormat(this.value)
  }

  sin.forEach((elem) => {
    elem.addEventListener('input', handleInput)
  })
}

export const removeSinFormat = (sinFormated: string) => {
  return sinFormated.replace(/-/g, '')
}

export function FormatCurrencyToNearestLowerMultipleToNoDecimals(data: number, roundDownTo: number) {
  let amount = 0
  amount = parseFloat(data.toString())
  amount -= Math.round(amount) % roundDownTo

  return FormatCurrency(amount, 0)
}

export function FormatCurrencyToNearestUpperMultipleToNoDecimals(data: number, roundUpTo: number) {
  const amount = Math.ceil(data / roundUpTo) * roundUpTo
  return FormatCurrency(amount, 0)
}

export function FormatCurrencyRoundedUpToNoDecimals(data: number) {
  const amount = Math.ceil(data)
  return FormatCurrency(amount)
}

export const formatDate = (date: Date | null | string | undefined): string => {
  if (!date) {
    return ''
  }

  if (typeof date === 'string') {
    const dateString = date.split('T')[0]
    if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return dateString
    }
    // eslint-disable-next-line no-param-reassign
    date = new Date(date)
  }

  if (date instanceof Date) {
    const year = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1
    const day = date.getUTCDate()
    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`
  }

  return ''
}
