import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend, { HttpBackendOptions } from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const initializeI18N = () =>
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(Backend)
    .init<HttpBackendOptions>({
      load: 'currentOnly',
      fallbackLng: 'fr',
      returnNull: false,
      returnObjects: true,
      debug: import.meta.env.DEV,
      supportedLngs: ['fr', 'en'],
      interpolation: {
        escapeValue: false,
      },
    })

export default i18n
