import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import apiClient from './api-client'

const SCOPE = 'available-credit'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

type IframeUrl = {
  url: string
  isForApplicant: boolean
}

export type IframeDto = {
  urls: IframeUrl[]
}

const getContractIframeUrl = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const response = await apiClient.get<IframeDto>(
    `/api/creditApplication/${encodeURIComponent(id)}/contract/iframeLink`,
  )

  return response.data
}

export function useGetContractIframeUrl(creditApplicationId: string, enabled: boolean) {
  return useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getContractIframeUrl,
    enabled,
    retry: 6, // Retry up to 4 times on failure
    retryDelay: 20000, // Refetch every 15 seconds
    staleTime: 0, // disable caching because it blocks first fetch sometimes
  })
}
