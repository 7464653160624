import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ContractWaitingLoader from './ContractWaitingLoader'
import ErrorPage from '../ErrorPage'

const ContractWaitingPage = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  if (!id) return <ErrorPage title={t('common.notFound')} />

  return <ContractWaitingLoader creditAppId={id} />
}

export default ContractWaitingPage
