import {
  MutationFunction,
  QueryFunctionContext,
  useMutation,
  useQueryClient,
  useQuery,
  RefetchOptions,
  QueryObserverResult,
} from '@tanstack/react-query'
import { Prequalification } from '@src/types/CreditApplicationSchema'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { EDocumentStatus } from '@src/types'
import {
  FilteredCreditApplication,
  CreditApplicationDocument,
  RequiredExternalStep,
} from './types/FilteredCreditApplication'
import { UploadFilesDto, UploadFilesResultDto, uploadFiles } from './upload-files-api'
import apiClient from './api-client'
import { transformPrequalToApi, transformCreditApplicationFromApi } from './credit-transform'
import { FilteredWorksheet } from './types/FilteredWorksheet'

export type SubmitDraftCreditApplicationDto = {
  recaptchaToken: string
  prequalification: Prequalification
}

export type PrequalifyDto = {
  creditApplicationId: string
  vouchedJobToken: string
}

const SCOPE = 'credit-application'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

const prequalify: MutationFunction<FilteredCreditApplication, PrequalifyDto> = async ({
  creditApplicationId,
  vouchedJobToken,
}: PrequalifyDto) => {
  const response = await apiClient.post(`api/creditapplication/${creditApplicationId}/prequalify`, {
    vouchedJobToken,
  })
  return response.data as FilteredCreditApplication
}

export function usePrequalify(): [MutationFunction<FilteredCreditApplication, PrequalifyDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: prequalify,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const needAssistanceTask: MutationFunction<
  FilteredCreditApplication,
  { creditApplicationId: string; comment: string | null }
> = async ({ creditApplicationId, comment }: { creditApplicationId: string; comment: string | null }) => {
  const response = await apiClient.post(`api/creditapplication/${creditApplicationId}/needAssistanceTask`, { comment })
  return response.data as FilteredCreditApplication
}

export function useNeedAssistanceTask() {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: needAssistanceTask,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })
}

export type NeedHelpDto = {
  creditApplicationId: string
  description: string
  comment?: string | null
}

const needHelp: MutationFunction<FilteredCreditApplication, NeedHelpDto> = async ({
  creditApplicationId,
  ...dto
}: NeedHelpDto) => {
  const response = await apiClient.post<FilteredCreditApplication>(
    `api/creditApplication/${creditApplicationId}/NeedHelp`,
    dto,
  )

  return response.data
}

export function useNeedHelp(): [MutationFunction<FilteredCreditApplication, NeedHelpDto>, boolean, boolean, boolean] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, isSuccess, isError } = useMutation({
    mutationFn: needHelp,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, isSuccess, isError]
}

const submitDraftCreditApplication: MutationFunction<
  FilteredCreditApplication,
  SubmitDraftCreditApplicationDto
> = async (dto: SubmitDraftCreditApplicationDto) => {
  const options: AxiosRequestConfig = {
    headers: {
      'x-recaptcha-token': dto.recaptchaToken,
    },
  }
  const prequal = transformPrequalToApi(dto.prequalification)
  const response = await apiClient.post(`api/creditapplication`, prequal, options)
  return response.data as FilteredCreditApplication
}

export function useSubmitDraftCreditApplication(): [
  MutationFunction<FilteredCreditApplication, SubmitDraftCreditApplicationDto>,
  boolean,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset, isSuccess } = useMutation({
    mutationFn: submitDraftCreditApplication,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, isSuccess, reset]
}

const cancelApplication: MutationFunction<FilteredCreditApplication, string> = async (id: string) => {
  const response = await apiClient.put(`api/creditApplication/${id}/cancel`)
  return response.data as FilteredCreditApplication
}

export function useCancelApplication(): [MutationFunction<FilteredCreditApplication, string>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: cancelApplication,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}
export type EditWorksheetDto = {
  amountRequested: number
  deliveryOn: string | null
  firstPaymentOn: string | null
  paymentFrequency: string
  term: number
  includeInsurance: boolean
  creditApplicationId: string
  status: string
  create: boolean
  versionTag: string | null
}

const updateWorksheet: MutationFunction<FilteredWorksheet, EditWorksheetDto> = async (dto: EditWorksheetDto) => {
  const response = dto.create
    ? await apiClient.post(`api/creditApplication/${dto.creditApplicationId}/worksheet`, dto)
    : await apiClient.put(`api/creditApplication/${dto.creditApplicationId}/worksheet`, dto)

  return response.data as FilteredWorksheet
}

export function useUpdateWorksheet(): [MutationFunction<FilteredWorksheet, EditWorksheetDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateWorksheet,
    onSuccess: (data, variables) => {
      const updated = {
        ...queryClient.getQueryData(keysFactory.detail(variables.creditApplicationId)),
      } as FilteredCreditApplication
      updated.worksheet = data
      queryClient.setQueryData(keysFactory.detail(variables.creditApplicationId), updated)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export function useUploadFiles(): [MutationFunction<UploadFilesResultDto, UploadFilesDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: uploadFiles,
    onSuccess: (data, variables) => {
      const updatedCreditApp = {
        ...queryClient.getQueryData(keysFactory.detail(data.creditApplicationId)),
      } as FilteredCreditApplication
      const doc: CreditApplicationDocument = {
        applicantType: variables.applicantType,
        status: EDocumentStatus.AwaitingApproval,
        subKey: variables.subKey,
        refusalReason: null,
        typeId: variables.typeId,
      }

      updatedCreditApp.documents = [...updatedCreditApp.documents]
      const existingDocIdx = updatedCreditApp.documents.findIndex(
        (x) =>
          x.typeId === variables.typeId && x.subKey === variables.subKey && x.applicantType === variables.applicantType,
      )
      if (existingDocIdx > -1) {
        updatedCreditApp.documents[existingDocIdx] = doc
      } else {
        updatedCreditApp.documents.push(doc)
      }

      queryClient.setQueryData(keysFactory.detail(data.creditApplicationId), updatedCreditApp)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const getCreditApplication = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>): Promise<FilteredCreditApplication> => {
  const response = await apiClient.get(`/api/creditApplication/${encodeURIComponent(id)}`)
  const fullData = response.data as FilteredCreditApplication
  const transformedData = transformCreditApplicationFromApi(fullData)
  return transformedData
}

export function useGetCreditApplicationById(
  creditApplicationId: string,
  shouldPoll: boolean = false,
  timer = 5000,
): [
  FilteredCreditApplication | null,
  boolean,
  (options?: RefetchOptions) => Promise<QueryObserverResult<FilteredCreditApplication, Error>>,
] {
  const { isFetching, data, refetch } = useQuery({
    queryKey: keysFactory.detail(creditApplicationId),
    queryFn: getCreditApplication,
    enabled: Boolean(creditApplicationId),
    refetchInterval: shouldPoll ? timer : undefined,
  })

  return [data ?? null, isFetching, refetch]
}
export type SubmitHardHitDto = {
  id: string
  applicantSin?: string | null | undefined
  requestedLoanAmount: number
}
const submitHardHit: MutationFunction<FilteredCreditApplication, SubmitHardHitDto> = async (creditApp) => {
  const response = await apiClient.put(`api/creditApplication/${creditApp.id}/submit`, creditApp)
  return response.data as FilteredCreditApplication
}

export function useSubmitHardHit(): [
  MutationFunction<FilteredCreditApplication, SubmitHardHitDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: submitHardHit,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

export type DeleteBankAccountRequestDto = {
  id: string
}

export type CompleteBankAccountRequestDto = {
  id: string
  externalServiceId: string
}

const completeApplicantFlinks: MutationFunction<RequiredExternalStep, CompleteBankAccountRequestDto> = async (
  dto: CompleteBankAccountRequestDto,
) => {
  const response = await apiClient.post(`api/creditApplication/${dto.id}/bankrequest/${dto.externalServiceId}/complete`)
  return response.data as RequiredExternalStep
}

export function useCompleteApplicantFlinks(): [
  MutationFunction<RequiredExternalStep, CompleteBankAccountRequestDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: completeApplicantFlinks,
    onSuccess: (data, variables) => {
      const updatedData = { ...queryClient.getQueryData(keysFactory.detail(variables.id)) } as FilteredCreditApplication
      const indexRequiredStep = updatedData.requiredExternalSteps.findIndex((x) => x.id === data.id)

      if (indexRequiredStep >= 0) updatedData.requiredExternalSteps[indexRequiredStep] = data

      queryClient.setQueryData(keysFactory.detail(variables.id), updatedData)
      return updatedData
    },
  })

  return [mutateAsync, isPending, reset]
}

const resetApplicantBankRequest: MutationFunction<FilteredCreditApplication, DeleteBankAccountRequestDto> = async (
  dto: DeleteBankAccountRequestDto,
) => {
  const response = await apiClient.delete(`api/creditApplication/${dto.id}/bankrequest`)
  return response.data as FilteredCreditApplication
}

export function useResetApplicantBankRequest(): [
  MutationFunction<FilteredCreditApplication, DeleteBankAccountRequestDto>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: resetApplicantBankRequest,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const continueWithComputedIncome: MutationFunction<FilteredCreditApplication, { id: string }> = async (dto) => {
  const response = await apiClient.post(`api/creditApplication/${dto.id}/ContinueWithComputedIncome`)
  return response.data as FilteredCreditApplication
}

export function useContinueWithComputedIncome(): [
  MutationFunction<FilteredCreditApplication, { id: string }>,
  boolean,
  () => void,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: continueWithComputedIncome,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const downloadVouchedReport: MutationFunction<
  AxiosResponse,
  { creditApplicationId: string; vouchedJobToken: string }
> = async ({ creditApplicationId, vouchedJobToken }) => {
  const response = await apiClient.post(`api/creditapplication/${creditApplicationId}/downloadVouchedReport`, {
    vouchedJobToken,
  })

  return response
}

// Only used when vouched failed
export function useDownloadVouchedReport(): [
  MutationFunction<AxiosResponse, { creditApplicationId: string; vouchedJobToken: string }>,
  boolean,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: downloadVouchedReport,
    onSuccess: (_data, variables) => {
      const creditApp = queryClient.getQueryData<FilteredCreditApplication>(
        keysFactory.detail(variables.creditApplicationId),
      )
      if (creditApp) {
        queryClient.setQueryData<FilteredCreditApplication>(keysFactory.detail(variables.creditApplicationId), {
          ...creditApp,
          vouchedFailedCount: (creditApp?.vouchedFailedCount ?? 0) + 1,
        })
      }
    },
  })

  return [mutateAsync, isPending]
}
