import { UseFormTrigger } from 'react-hook-form'
import { Asserts } from 'yup'
import { reportErrorToConsole } from './error-logger'

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

export const setYearMonthValueFromMonths = (
  months: number,
  yearSelect: HTMLSelectElement,
  monthSelect: HTMLSelectElement,
) => {
  if (months) {
    yearSelect.value = ''
    monthSelect.value = ''
    const year = Math.trunc(months / 12)
    const month = months % 12
    if (year > 0) yearSelect.value = year.toString()
    else yearSelect.selectedIndex = 0

    if (month > 0) monthSelect.value = month.toString()
    else monthSelect.selectedIndex = 0
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateObjectValue = (object: Asserts<any>, trigger: UseFormTrigger<Asserts<any>>) => {
  const validate = (field: string) => trigger(field)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validateChildObject = (parentKey: string, value: { [k: string]: any }) => {
    Object.entries(value).forEach(([childKey, childValue]) => {
      if (childValue) {
        if (typeof childValue !== 'object') validate(`${parentKey}.${childKey}`).catch(reportErrorToConsole)
        else if (typeof childValue === 'object')
          setTimeout(() => {
            validateChildObject(`${parentKey}.${childKey}`, childValue as Record<string, unknown>)
          }, 0)
      }
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  Object.entries(object).forEach(([key, value]) => {
    if (value) {
      if (typeof value !== 'object') validate(key).catch(reportErrorToConsole)
      else if (typeof value === 'object') validateChildObject(key, value)
    }
  })
}

export const getValuesFromObject = (keys: string[], values: { [k: string]: unknown }) => {
  const obj: { [k: string]: unknown } = {}
  keys.forEach((key) => {
    obj[key] = values[key]
  })
  return obj
}

// Tooltip Adjustment
export const helpTip = () => {
  const helpTips = document.querySelectorAll('.help-tip')

  function handleMouseenter(this: HTMLInputElement) {
    const tooltip = this.querySelector('p')
    if (tooltip) {
      const tooltipWidth = tooltip.offsetWidth
      const tooltipOffset = this.getBoundingClientRect().left
      const viewportWidth = window.innerWidth

      if (tooltipOffset + tooltipWidth > viewportWidth) {
        tooltip.style.left = `${viewportWidth - tooltipOffset - tooltipWidth - 20}px`
        tooltip.classList.add('no-arrow') // Add a class to hide the arrow
      }
    }
  }

  function handleMouseleave(this: HTMLInputElement) {
    const tooltip = this.querySelector('p')
    if (tooltip) {
      tooltip.style.left = '-0.4rem'
      tooltip.classList.remove('no-arrow')
    }
  }

  helpTips.forEach((element) => {
    element.addEventListener('mouseenter', handleMouseenter)
    element.addEventListener('mouseleave', handleMouseleave)
  })
}

export const scrollToTop = () => {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

const convertStringToArrayBuffer = (str: string) => {
  const textEncoder = new TextEncoder()
  return textEncoder.encode(str).buffer
}
export function convertFileToArrayBuffer(file: File): Promise<ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    if (!file || !file.name) {
      reject(new Error('Invalid or missing file.'))
    }

    const reader = new FileReader()

    reader.onload = () => {
      const arrayBuffer: ArrayBuffer | null | string = reader.result

      if (arrayBuffer === null) {
        resolve(null)
        return
      }
      if (typeof arrayBuffer === 'string') {
        resolve(convertStringToArrayBuffer(arrayBuffer))
        return
      }
      if (!arrayBuffer) {
        reject(new Error('Failed to read file into ArrayBuffer.'))
        return
      }

      resolve(arrayBuffer)
    }

    reader.onerror = () => {
      reject(new Error('Error reading file.'))
    }

    reader.readAsArrayBuffer(file)
  })
}

export const replaceWithNonBreakingHyphen = (hyphenatedString: string) => {
  return hyphenatedString.replaceAll('-', '\u2011')
}

export const replaceLastSpaceWithNbsp = (str: string) => {
  return str.replace(/\s(?=\S*$)/, '\u00A0')
}

/**
 * Workaround de l'optimisation que fait Vite sur les check de constante et de variables d'environnement
 * Vite optimise tous les if avec une comparaison de 2 constantes ex: import.meta.env.UNE_VAR === 'true'
 * Vite efface le code qui n'est jamais utilisé. Dans l'exemple précédent Vite va seulement garder le code du if (si true) ou du else (si false)
 */
export function getRuntimeVariable<T = string>(variable: string): T {
  return JSON.parse(variable) as T
}
