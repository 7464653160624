import { useGetCreditApplicationById, useNeedAssistanceTask } from '@src/api/credit-api'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { AsyncActionButton, ErrorPage, InputText, Loader } from '@src/components'
import SectionTitle from '@src/components/PageSectionTitle'
import { reportErrorToConsole } from '@src/services/error-logger'
import { ChangeEventHandler, FormEventHandler, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

type Props = {
  creditApp: FilteredCreditApplication
}

const FailedIdentification = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const { mutateAsync: needAssistanceTask, isPending, isSuccess } = useNeedAssistanceTask()
  const [comment, setComment] = useState<string>('')

  const handleNeedAssistance = useCallback<FormEventHandler>(
    (ev) => {
      ev.preventDefault()
      needAssistanceTask({ creditApplicationId: creditApp.id, comment: comment || null }).catch(reportErrorToConsole)
    },
    [comment, creditApp.id, needAssistanceTask],
  )

  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setComment(e.target.value)
  }, [])

  if (isSuccess)
    return (
      <main className="step-0">
        <SectionTitle title={t('identification.assistanceRequested')} />
        <h1>{t('identification.agentContact')}</h1>
      </main>
    )

  return (
    <main className="step-0">
      <SectionTitle title={t('identification.failed')} />
      <h1>{t('identification.explanation')}</h1>
      <Link className="btn btn-blue primary-action-btn" to={`/creditApplication/${creditApp.id}/vouched`} replace>
        <span>{t('identification.retryVerification')}</span>
        <i className="fa-regular fa-paper-plane-top" />
      </Link>
      <div className="paragraph">
        <p>{t('identification.createNew')}</p>
      </div>
      <form onSubmit={handleNeedAssistance} style={{ width: '100%' }}>
        <InputText placeholder={t('identification.comment')} onChange={handleOnChange} disabled={isPending} />
        <AsyncActionButton type="submit" isPending={isPending} disabled={isPending}>
          {t('identification.requestAssistance')}
        </AsyncActionButton>
      </form>
    </main>
  )
}

const FailedIdentificationPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [creditApp, loading] = useGetCreditApplicationById(id!)

  if (loading) return <Loader />
  if (!creditApp) return <ErrorPage title={t('common.notFound')} />
  if (creditApp.vouchedFailedCount > 1)
    return <ErrorPage title={t('common.unexpectedError')} description={t('identification.failed_no_retry')} />

  return <FailedIdentification creditApp={creditApp} />
}

export default FailedIdentificationPage
